
body[data-layout-mode="dark"] {
    background-color: $gray-dark-200;
    color: $gray-dark-600;


    .h1,.h2,.h3,.h4,.h5,.h6,
    h1,h2,h3,h4,h5,h6 {
        color: $gray-dark-600;
    }
}

// Gantt chart
.e-gantt .e-gantt-chart .e-gantt-milestone {
    background-color: #F2D8A7 !important;
    border-color: #000000 !important;
    border: 1px solid;
}

.e-gantt .e-gantt-chart .e-gantt-parent-taskbar-inner-div,
.e-gantt .e-gantt-chart .e-gantt-child-taskbar-inner-div {
   background-color: #91B7D9 !important;
}

.react-calendar{
    border: unset !important;
    border-radius: 4px;
    box-shadow: 0 30px 40px 0 rgba(16, 36, 94, .2);
    margin: auto;
}

.black-50-bg{
    background-color: rgba(0, 0, 0, 0.03);
}