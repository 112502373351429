@font-face {
    font-family: Hauora;
    src: url("../../../public/fonts/Hauora-Regular.ttf");
}
.stats-text {
    font-family: Hauora, sans-serif;
    font-size: 11px;
    line-height: 15px;
    letter-spacing: 0.02em;
    text-align: left;
    font-weight: 600;
    color: #000000BD;
}
.stats-value {
    font-family: Hauora, sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #194973;

}
.progress-bar {
    background-color: #F2D8A7 !important;
    color: #194973 !important;
    font-weight: 900;

}
.primary-text-color {
    color: #194973 !important;
}
.progress-section h5, h6 {
    color: #000000D6;
    font-family: Hauora, sans-serif;
}
.progress-section h5 {
    font-size: 14px;
    font-weight: 800;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
}
.progress-section h6 {
    font-family: Hauora, sans-serif;
    font-size: 11px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.06em;
    text-align: left;
}
.progress-section span{
    color: rgba(0, 0, 0, 0.74) !important;
    font-family: Hauora, sans-serif;
    font-size: 11px;
}
.progress-section p {
    font-family: Hauora, sans-serif;
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #0000008F;
}
.progress-section p span {
    color: #000000BD;

}
.report-nav-btn {
    background-color: #D1DBE399;
    font-family: Hauora, sans-serif;
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0.02em;
    color: #000000D6;
    border-radius: 4px;
    border: none;
    height: 36px;
    text-align: center;
}
.report-nav-btn-active {
    background-color: #194973;
    font-family: Hauora, sans-serif;
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    border-radius: 4px;
    border: none;
    height: 36px;
    text-align: center;
}
.disc-color {
    width: 10px !important;
    height: 10px !important;
    border-radius: 50%;
    display: inline;

}
.inventory-cost  thead th, .task-progress thead th {
    font-family: Hauora, sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #194973 !important;
    background-color: #00000008 !important;
    border-bottom: #0000001F solid 1px;
}
.task-progress tbody td {
    color: rgba(0, 0, 0, 0.87);
    font-family: Hauora, sans-serif;
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
    text-align: left;
    border-bottom: none;
}
.list-progress {
    position: relative;
}
.list-progress span {
    position: absolute;
    right: 0;
}
.inventory-cost tbody td {
    height: 47px;
    font-family: Hauora, sans-serif;

}
.inventory-cost tbody td:first-child, .task-progress tbody td:first-child {
    font-size: 14px;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
}
.task-progress tbody td:first-child {
    font-size: 12px;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #1E1E1E;
}
.reports-list .card-title {
    font-family: Hauora, sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 21.86px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #194973;
}
.reports-list  thead th {
    font-family: Hauora, sans-serif;
    font-size: 14px;
    font-weight: 800;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000BD !important;
    background-color: #00000008 !important;
    border-bottom: #0000001F solid 1px;
}
.reports-list tbody td {
    height: 47px;
    background-color: #F2F7F9;
    font-family: Hauora, sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 19.12px;
    text-align: left;
    color: #0000008F;


}
.reports-list tbody td:first-child {
    font-family: Hauora, sans-serif;
    font-size: 14px;
    font-weight: 700 !important;
    line-height: 19.12px;
    text-align: left;
    color: #000000BD;
}
.page-title-box h4 {
    font-family: Hauora, sans-serif;
    font-size: 24px !important;
    font-weight: 800;
    line-height: 32.78px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #194973;
}
.chart-color {
    height: 9px;
    width: 9px;
    border-radius: 50%;
}
.chart-legend-item {
    background-color: #F2F2F2;
    padding: 11px;
    height: 37px;
    border-radius: 4px;
}
.status-badge {
    background-color: #FCF7ED;
    border-radius: 200px;
    color: #615643;
    font-family: Hauora, sans-serif;
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
    text-align: left;

}
.progress-status-badge {
    background-color: #F2F7F9;
    border-radius: 200px;
    color: #194973;
    font-family: Hauora, sans-serif;
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
    text-align: left;
}
.complete-status-badge {
    background-color: #F6FEF9;
    border-radius: 200px;
    color: #32D583;
    font-family: Hauora, sans-serif;
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
    text-align: left;
}
.delayed-status-badge {
    background-color: #ff00001b;
    border-radius: 200px;
    color: #ff0000;
    font-family: Hauora, sans-serif;
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
    text-align: left;
}
.subtask td:first-child{
    font-family: Hauora, sans-serif;
    font-size: 12px !important;
    font-weight: 500 !important;
    line-height: 18px !important;
    text-align: left;
    color: #1E1E1E;
    padding-left: 2rem;

}
.subtask td, .subtask1 td {
    border-bottom: #e0e0e0;

}
.assignee-badge {
    border-radius: 50%;
    transition: all 0.2s;
    background: #F2D8A7;
    height: 2rem;
    width: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 0.7rem;
    text-transform: uppercase;
}
.subtask1 td:first-child{
    font-family: Hauora, sans-serif;
    font-size: 12px !important;
    font-weight: 500 !important;
    line-height: 18px !important;
    text-align: left;
    color: #1E1E1E;
    padding-left: 4rem;

}
.table-icon {
    height: 15px;
}
.schedule-nav span {
    font-family: Hauora, sans-serif;
    font-size: 11px;
    font-weight: 800 !important;
    line-height: 15.03px;
    text-align: left;
    color:#000000D6;
    margin-left: .5rem;
}
.schedule-nav div {
    padding: 3px 10px 3px 10px;
}
.schedule-nav .active {
    color: #194973;
   background: #F2F7F9;
    padding: 3px 10px 3px 10px;
}
.schedule-nav .active span {
   color: #194973 !important;

}
.log-image {
    height: 150px;
    object-fit: cover;
}
.pagination {
    list-style: none;
    height: 21.5px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2px;
    cursor: pointer;
}
.pagination li {
    padding: 1rem;
}
.paginate .active {
    background-color: #194973;
    color: white;
    border-radius: 50%;
}

.page-item {
    list-style: none;
    padding: 2px 12px;
    height: 31.5px;
    width: 31.5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2px;
}
.modal-content {
    overflow-y: scroll !important;
}
.add-new {
    background: rgba(242, 216, 167, 1);
    color: rgba(25, 73, 115, 1);
    border: none;
    padding: 10px 30px !important;
    font-weight: 700;
    box-shadow: none !important;
}
.adduser-role-modal{
    position: absolute !important;
    width: 50% !important;
    right: 0 !important;
}
.config-left-menu .config-menu-item, .config-left-menu .active-config-menu-item {
    height:2.5rem ;
    border-radius: 5px;
    margin-bottom: .5rem;
    display: flex;
    align-items: center;
    padding-left: .5rem;
    padding-right: .5rem;
    justify-content: space-between;
    cursor: pointer;
}
.config-left-menu .config-menu-item .icon, .config-left-menu .active-config-menu-item .icon {
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

}
.config-left-menu .active-config-menu-item .icon {
    background-color: white;
    color: #194973;

}
.config-left-menu .config-menu-item{
    background-color: white;

}
.config-left-menu .active-config-menu-item {
    background-color: #194973;
    color: white;
}
.config-top-menu {
    border-bottom:  1px solid #7D7D7D;
}
.config-top-menu .active-config-menu-item {
    border-bottom:  3px solid #194973;
    color: #194973;
    font-weight: bold;
}
.config-top-menu ul{
    list-style-type: none;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0;
    margin-top: 1rem;
}
.config-top-menu ul li {
    display: inline-block;
    margin-right: 2rem;
}
.card-title a:hover{
    text-decoration: underline !important;
}
.adduser-role-modal{
    animation: left-to-center linear 1s;
    animation-iteration-count: 1;
    transform-origin: 50% 50%;
    -webkit-animation: left-to-center linear 1s;
    -webkit-animation-iteration-count: 1;
    -webkit-transform-origin: 50% 50%;
    -moz-animation: left-to-center linear 1s;
    -moz-animation-iteration-count: 1;
    -moz-transform-origin: 50% 50%;
    -o-animation: left-to-center linear 1s;
    -o-animation-iteration-count: 1;
    -o-transform-origin: 50% 50%;
    -ms-animation: left-to-center linear 1s;
    -ms-animation-iteration-count: 1;
    -ms-transform-origin: 50% 50%;
}
.create-modal h6 {
    font-weight: lighter;
    color: #194973;
    display: inline-block;
    margin-left: 1rem;
    cursor: pointer;

}
@keyframes left-to-center{
    0% {
        transform:  translate(250px,0px)  ;
    }
    100% {
        transform:  translate(0px,0px)  ;
    }
}

@-moz-keyframes left-to-center{
    0% {
        -moz-transform:  translate(250px,0px)  ;
    }
    100% {
        -moz-transform:  translate(0px,0px)  ;
    }
}

@-webkit-keyframes left-to-center {
    0% {
        -webkit-transform:  translate(250px,0px)  ;
    }
    100% {
        -webkit-transform:  translate(0px,0px)  ;
    }
}

@-o-keyframes left-to-center {
    0% {
        -o-transform:  translate(250px,0px)  ;
    }
    100% {
        -o-transform:  translate(0px,0px)  ;
    }
}

@-ms-keyframes left-to-center {
    0% {
        -ms-transform:  translate(250px,0px)  ;
    }
    100% {
        -ms-transform:  translate(0px,0px)  ;
    }
}