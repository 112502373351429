@font-face {
    font-family: Hauora;
    src: url("../../../public/fonts/Hauora-Regular.ttf");
}
.dashboard-navigation {
    background: #F2F7F9;
}
.dashboard-navigation .nav-item {
    padding: 5px 18px 5px 18px ;
    gap: 10px;
    border-radius: 4px;
    background: #D1DBE399;
    display: flex;
    align-items: center;
}
.dashboard-navigation .nav-item a {
    font-family: Hauora, sans-serif;
    font-size: 12px;
    font-weight: 700;
    line-height: 12px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #000000D6;
}
.dashboard-navigation .active {
    padding: 7px 18px 7px 18px ;
    gap: 10px;
    border-radius: 4px;
    background: #194973;
}
.dashboard-navigation .active a {
    font-family: Hauora, sans-serif;
    font-size: 12px;
    font-weight: 700;
    line-height: 16.39px;
    letter-spacing: 0.02em;
    text-align: left;
    color: white;
}
.navigation-title h4 {
    font-family: Hauora, sans-serif;
    font-size: 24px;
    font-weight: 800 !important;
    line-height: 32.78px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #194973;
}
.settings {
    border-left: 1px solid #0000001F;
    padding-left: 0;
}
.settings .setting-menu, .settings .active-setting-menu {
    font-size: 14px;
    height: 20px;
    margin-bottom: 1rem;
    font-family: Hauora, sans-serif;
    font-weight: 700;
    line-height: 19.12px;
    letter-spacing: 0.02em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    padding-left: 1rem;
}
.setting-menu {
    color: #000000D6;
}
.active-setting-menu {
    color: #194973;
    border-left: 3px solid #194973;

}