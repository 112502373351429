// 
// _card.scss
// 

.card {
    cursor: pointer;
    margin-bottom: $grid-gutter-width;
}

.card-drop {
    color: $body-color;
}

.card-title {
    margin: 0 0 7px 0;
    font-family: Hauora;
    font-size: 17px;
    font-weight: 800;
    line-height: 23.22px;
    text-align: left;
    color: #194973;
    text-transform: capitalize;

}
.project-dets {
    font-family: Hauora, sans-serif;
    font-size: 10px;
    font-weight: 700;
    line-height: 13.66px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #000000D6;
}
.project-dets span{
    font-family: Hauora, sans-serif;
    font-size: 10px;
    font-weight: 500;
    line-height: 13.66px;
    letter-spacing: 0.02em;
    text-align: left;

}
.card-title-desc {
    color: $card-title-desc;
    margin-bottom: 0;
    font-size: 13px;
}


.card-header-tabs {
    margin-top: -$card-cap-padding-y;
}

.card-header-pills {
    margin: -$card-cap-padding-y / 2;
}

body[data-layout-mode="dark"] {
    .card, .card-header,
    .modal-content, .offcanvas {
        background-color: $gray-dark-200;
        border-color: lighten($gray-dark-200,4%);
    }

    .card-title-desc{
        color: $gray-dark-400;
    }
}