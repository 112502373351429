@font-face {
    font-family: Hauora;
    src: url("../../../public/fonts/Hauora-Regular.ttf");
}
table {
    font-family: Hauora, sans-serif !important;
}
table thead tr th {
    background: rgba(0, 0, 0, 0.03) !important;
    font-family: Hauora, sans-serif;
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: -0.011em;
    text-align: left;
    color: rgba(25, 73, 115, 1) !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.24)

}
.table tbody tr td{
    border: none;
}
.table tbody tr:nth-child(even) td {
    background: rgba(0, 0, 0, 0.03) !important;
}
.table tbody tr td:first-child {
    color: rgba(30, 30, 30, 1) !important;
    font-weight: 550 !important;
    font-size: 14px !important;
}
.table tbody tr td:not(:first-child){
    color:  rgba(0, 0, 0, 0.56) !important;
    font-size: 14px !important;
}