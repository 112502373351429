// 
// _badge.scss
// 


.badge {
  padding: 4px 13px;
  border-radius: 1rem;
  &[href] {
    &:hover,
    &:focus {
      color: $white;
    }
  }
}

// Soft badge
@mixin badge-variant-soft($bg) {
  color: $bg;
  background-color: rgba($bg, 0.18);

  &[href] {
    &:hover,
    &:focus {
      color: $bg;
      text-decoration: none;
      background-color: rgba($bg, 0.4);
    }
  }
}

@each $color, $value in $theme-colors {
  .badge {
  &.bg-#{$color} {
    &[href] {
      &:hover,
      &:focus {
        background-color: darken($value, 4%) !important;
      }
    }
  }
}

.badge{
  &.bg-light{
    color: $body-color;

    &[href] {
      &:hover,
      &:focus {
        color: $body-color;
      }
    }
  }
}

  .badge-soft-#{$color} {
      @include badge-variant-soft($value);
  }
  
}

.rounded-pill{
   padding-right: .6em;
    padding-left: .6em;
}

// Dark badge

.badge.bg-dark{
  color: $light;
}

body[data-layout-mode="dark"] {
  .badge-soft-dark{
    color: $gray-dark-600;
    background-color: rgba($gray-dark-400, 0.18);

    &[href] {
      &:hover,
      &:focus {
        color: $gray-dark-600;
        text-decoration: none;
        background-color: rgba($gray-dark-400, 0.4);
      }
    }
  }
}

.badge-completed{
  background-color: $white-300;
}

.badge-completed-text{
  font-weight: 700;
  color: $green100;
}

.badge-on-track{
  background-color: $white-100;
}

.badge-on-track-text{
  font-weight: 700;
  color: $blue100;
}

.badge-delayed{
  background-color: $white-200;
}

.badge-delayed-text{
  font-weight: 700;
  color: $black-100;
}

.badge-actual-delayed{
  background-color: #ff00001b;
}

.badge-actual-delayed-text{
  font-weight: 700;
  color: #ff0000;
}

.badge-success{
  background-color: #F6FEF9
}

.badge-success-text{
  font-weight: 700;
  color: #32D583
}

.badge-assigned{
  background-color: #F2D8A7
}

.badge-assigned-text{
  font-weight: 700;
  color: #615643
}